import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';

const BASE_URL = 'https://localhost:3200';

// Initialize the i18next module (see i18next documentation for more configuration options)
export const i18nextCore = (locale: string, url: string = BASE_URL) => {
  return i18next.use(i18nextXHRBackend).init({
    lng: locale,
    fallbackLng: 'en',
    keySeparator: '$',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      // Here you define where the translation files are served from
      loadPath: `${url}/assets/locale/my-translates-platform-app/messages_{{lng}}.json`,
      crossDomain: true,
    },
    react: { wait: true },
  });
};
