import { ComponentDefinition } from "@wix/platform-editor-sdk";
import { TFunction } from "i18next";

const popupCloseIconButton = {
  type: "Component",
  skin: "svgshape.v2.Svg_9a8686831e874878a55a90925c0feb6c",
  layout: {
    width: 35,
    height: 35,
    scale: 1,
    rotationInDegrees: 0,
    anchors: [],
    fixedPosition: true,
    docked: {
      top: {
        px: 60,
      },
      right: {
        px: 60,
      },
    },
  },
  componentType: "wysiwyg.viewer.components.PopupCloseIconButton",
  props: {
    type: "SvgShapeProperties",
    maintainAspectRatio: true,
  },
  connections: {
    type: "ConnectionList",
    items: [
      {
        type: "WixCodeConnectionItem",
        role: "closeIcon",
      },
      {
        type: "ConnectionItem",
        role: "closeIcon",
        isPrimary: true,
        controllerId: "dataItem-kdy6319y",
      },
    ],
    id: "connection-kdy631ar",
  },
  style: {
    type: "TopLevelStyle",
    style: {
      groups: {},
      properties: {
        fillcolor: "#000",
      },
      propertiesSource: {
        fillcolor: "value",
      },
    },
    componentClassName: "wysiwyg.viewer.components.PopupCloseIconButton",
    styleType: "custom",
    skin: "svgshape.v2.Svg_9a8686831e874878a55a90925c0feb6c",
  },
  mobileStructure: {
    layout: {
      width: 18,
      height: 18,
      x: 19,
      y: 17,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
  },
};

const title = (t: TFunction): ComponentDefinition => {
  return {
    type: "Component",
    id: "comp-kdy631aj",
    skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
    layout: {
      width: 300,
      height: 43,
      x: 83.5,
      y: 53,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: "wysiwyg.viewer.components.WRichText",
    parent: "comp-kdy631a0",
    data: {
      type: "StyledText",
      text: `<h1 class="font_0" style="font-size: 40px; text-align: center;"><span style="font-size:40px;"><span class="color_15">${t(
        "components.title.label"
      )}</span></span></h1>`,
      stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
      linkList: [],
      id: "dataItem-kdy631ak",
    },
    props: {
      type: "WRichTextProperties",
      brightness: 1,
      packed: true,
      verticalText: false,
      id: "propItem-kdy631an",
    },
    // @ts-ignore
    connections: {
      type: "ConnectionList",
      items: [
        {
          type: "WixCodeConnectionItem",
          role: "title",
        },
        {
          type: "ConnectionItem",
          role: "title",
          isPrimary: true,
          controllerId: "dataItem-kdy6319y",
        },
      ],
      id: "connection-kdy631ar",
    },
    // @ts-ignore
    style: "txtNew",
    mobileStructure: {
      layout: {
        width: 280,
        height: 36,
        x: 10,
        y: 59,
        scale: 1.1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: "WRichTextProperties",
        brightness: 1,
        packed: true,
        verticalText: false,
        id: "mobile_propItem-kdy631an",
      },
    },
  };
};

const signUpLink = (t: TFunction): ComponentDefinition => {
  return {
    type: "Component",
    id: "comp-ke10phlg1",
    skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
    layout: {
      x: 83.5,
      y: 110,
      fixedPosition: false,
      width: 300,
      height: 20,
      scale: 1,
      rotationInDegrees: 0,
    },
    componentType: "wysiwyg.viewer.components.WRichText",
    parent: "comp-ke10phla",
    data: {
      type: "StyledText",
      text: `<p class="font_8" style="font-size: 16px; line-height: 1.3em; text-align: center;"><span style="font-size:16px;"><span class="color_15" style="letter-spacing:0.03em">${t(
        "components.subTitle.label"
      )} <span style="text-decoration:underline" class="link" tabindex="0">${t(
        "components.signUpLink.label"
      )}</span></span></span></p>`,
      stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
      linkList: [],
      id: "dataItem-ke10phlh",
    },
    props: {
      type: "WRichTextProperties",
      brightness: 1,
      packed: true,
      minWidth: null,
      verticalText: false,
      id: "propItem-ke10phlh1",
    },
    // @ts-ignore
    connections: {
      type: "ConnectionList",
      items: [
        {
          type: "WixCodeConnectionItem",
          role: "signUpLink",
        },
        {
          type: "ConnectionItem",
          role: "signUpLink",
          isPrimary: true,
          controllerId: "dataItem-kdy6319y",
        },
      ],
      id: "connection-ke10phlh2",
    },
    style: "txtNew",
    mobileStructure: {
      layout: {
        width: 260,
        height: 20,
        x: 20,
        y: 114,
        scale: 1.0666666666666667,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: "WRichTextProperties",
        brightness: 1,
        packed: true,
        verticalText: false,
        id: "mobile_propItem-ke10phlh1",
      },
    },
  };
};

const email = (t: TFunction): ComponentDefinition => {
  const placeholder = t("components.email.label");
  return {
    type: "Component",
    id: "comp-kdy631ax",
    skin: "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin",
    layout: {
      x: 83.5,
      y: 156,
      fixedPosition: false,
      width: 300,
      height: 66,
      scale: 1,
      rotationInDegrees: 0,
    },
    componentType: "wysiwyg.viewer.components.inputs.TextInput",
    parent: "comp-kdy631a0",
    data: {
      type: "TextInput",
      value: "",
      textType: "email",
      label: placeholder,
      placeholder,
    },
    props: {
      type: "TextInputProperties",
      required: true,
      inputHeight: 42,
      inputHeightMobile: 44,
      labelMargin: 8,
      labelPadding: 0,
      textAlignment: "left",
      textPadding: 12,
      placeholder,
      defaultTextType: "placeholderText",
      id: "propItem-kdy631b3",
    },
    behaviors: {
      type: "ObsoleteBehaviorsList",
      items: "[]",
    },
    connections: {
      type: "ConnectionList",
      items: [
        {
          type: "WixCodeConnectionItem",
          role: "email",
        },
        {
          type: "ConnectionItem",
          role: "email",
          isPrimary: true,
          controllerId: "dataItem-kdy6319y",
        },
      ],
      id: "connection-kdy631b51",
    },
    style: {
      id: "style-kdy631b5",
      type: "ComponentStyle",
      // @ts-ignore
      style: {
        properties: {
          "alpha-bg": "1",
          "alpha-bge": "1",
          "alpha-bgf": "1",
          "alpha-bgh": "1",
          "alpha-brd": "1",
          "alpha-brde": "1",
          "alpha-brdf": "1",
          "alpha-brdh": "1",
          bg: "color_11",
          bgd: "#FFFFFF",
          bge: "color_11",
          bgf: "color_11",
          bgh: "color_11",
          "boxShadowToggleOn-shd": "false",
          brd: "color_15",
          brdd: "#DBDBDB",
          brde: "#FF4040",
          brdf: "color_15",
          brdh: "color_15",
          brw: "1px",
          brwe: "1px",
          brwf: "2px",
          brwh: "2px",
          fnt: "font_8",
          fntlbl: "font_8",
          rd: "0px",
          shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
          txt: "color_15",
          txt2: "color_14",
          txtd: "#DBDBDB",
          txtlbl: "color_15",
          txtlblrq: "color_14",
        },
        propertiesSource: {
          "alpha-bg": "value",
          "alpha-bge": "value",
          "alpha-bgf": "value",
          "alpha-bgh": "value",
          "alpha-brd": "value",
          "alpha-brde": "value",
          "alpha-brdf": "value",
          "alpha-brdh": "value",
          bg: "theme",
          bgd: "value",
          bge: "theme",
          bgf: "theme",
          bgh: "theme",
          "boxShadowToggleOn-shd": "value",
          brd: "theme",
          brdd: "value",
          brde: "value",
          brdf: "theme",
          brdh: "theme",
          brw: "value",
          brwe: "value",
          brwf: "value",
          brwh: "value",
          fnt: "theme",
          fntlbl: "theme",
          rd: "value",
          shd: "value",
          txt: "theme",
          txt2: "theme",
          txtd: "value",
          txtlbl: "theme",
          txtlblrq: "theme",
        },
      },
      componentClassName: "wysiwyg.viewer.components.inputs.TextInput",
      pageId: "",
      compId: "",
      styleType: "custom",
      skin: "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin",
    },
    mobileStructure: {
      layout: {
        width: 260,
        height: 44,
        x: 20,
        y: 170,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: "TextInputProperties",
        required: true,
        inputHeight: 37,
        inputHeightMobile: 44,
        labelMargin: 14,
        labelPadding: 0,
        textAlignment: "left",
        textPadding: 14,
        placeholder,
        defaultTextType: "placeholderText",
        id: "mobile_propItem-kdy631b3",
      },
    },
  };
};

const password = (t: TFunction): ComponentDefinition => {
  const placeholder = t("components.password.label");
  return {
    type: "Component",
    id: "comp-kdy631b71",
    skin: "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin",
    layout: {
      x: 83.5,
      y: 244,
      fixedPosition: false,
      width: 300,
      height: 65,
      scale: 1,
      rotationInDegrees: 0,
    },
    componentType: "wysiwyg.viewer.components.inputs.TextInput",
    parent: "comp-kdy631a0",
    data: {
      placeholder,
      type: "TextInput",
      value: "",
      textType: "password",
      label: placeholder,
      id: "dataItem-kdy631b8",
    },
    props: {
      type: "TextInputProperties",
      required: true,
      inputHeight: 42,
      inputHeightMobile: 44,
      labelMargin: 8,
      labelPadding: 0,
      textAlignment: "left",
      textPadding: 12,
      placeholder,
      defaultTextType: "placeholderText",
      id: "propItem-kdy631b81",
    },
    behaviors: {
      type: "ObsoleteBehaviorsList",
      items: "[]",
    },
    connections: {
      type: "ConnectionList",
      items: [
        {
          type: "WixCodeConnectionItem",
          role: "password",
        },
        {
          type: "ConnectionItem",
          role: "password",
          isPrimary: true,
          controllerId: "dataItem-kdy6319y",
        },
      ],
      id: "connection-kdy631b92",
    },
    style: {
      id: "style-kdy631b91",
      type: "ComponentStyle",
      // @ts-ignore
      style: {
        properties: {
          "alpha-bg": "1",
          "alpha-bge": "1",
          "alpha-bgf": "1",
          "alpha-bgh": "1",
          "alpha-brd": "1",
          "alpha-brde": "1",
          "alpha-brdf": "1",
          "alpha-brdh": "1",
          bg: "color_11",
          bgd: "#FFFFFF",
          bge: "color_11",
          bgf: "color_11",
          bgh: "color_11",
          "boxShadowToggleOn-shd": "false",
          brd: "color_15",
          brdd: "#DBDBDB",
          brde: "#FF4040",
          brdf: "color_15",
          brdh: "color_15",
          brw: "1px",
          brwe: "1px",
          brwf: "2px",
          brwh: "2px",
          fnt: "font_8",
          fntlbl: "font_8",
          rd: "0px",
          shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
          txt: "color_15",
          txt2: "color_14",
          txtd: "#DBDBDB",
          txtlbl: "color_15",
          txtlblrq: "color_14",
        },
        propertiesSource: {
          "alpha-bg": "value",
          "alpha-bge": "value",
          "alpha-bgf": "value",
          "alpha-bgh": "value",
          "alpha-brd": "value",
          "alpha-brde": "value",
          "alpha-brdf": "value",
          "alpha-brdh": "value",
          bg: "theme",
          bgd: "value",
          bge: "theme",
          bgf: "theme",
          bgh: "theme",
          "boxShadowToggleOn-shd": "value",
          brd: "theme",
          brdd: "value",
          brde: "value",
          brdf: "theme",
          brdh: "theme",
          brw: "value",
          brwe: "value",
          brwf: "value",
          brwh: "value",
          fnt: "theme",
          fntlbl: "theme",
          rd: "value",
          shd: "value",
          txt: "theme",
          txt2: "theme",
          txtd: "value",
          txtlbl: "theme",
          txtlblrq: "theme",
        },
      },
      componentClassName: "wysiwyg.viewer.components.inputs.TextInput",
      pageId: "",
      compId: "",
      styleType: "custom",
      skin: "wysiwyg.viewer.skins.appinputs.AppsTextInputSkin",
    },
    mobileStructure: {
      layout: {
        width: 260,
        height: 44,
        x: 20,
        y: 226,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: "TextInputProperties",
        required: true,
        inputHeight: 37,
        inputHeightMobile: 44,
        labelMargin: 14,
        labelPadding: 0,
        textAlignment: "left",
        textPadding: 14,
        placeholder,
        defaultTextType: "placeholderText",
        id: "propItem-kdy631b81",
      },
    },
  };
};

const forgotPasswordLink = (t: TFunction): ComponentDefinition => ({
  type: "Component",
  skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
  layout: {
    width: 300,
    height: 18,
    x: 83.5,
    y: 318,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: "wysiwyg.viewer.components.WRichText",
  parent: "comp-khnhanyx1",
  data: {
    type: "StyledText",
    text: `<p class="font_8" style="font-size: 14px; line-height: 1.3em;"><span style="font-size:14px;"><span class="color_15"><span style="letter-spacing:0.03em"><span style="text-decoration:underline" tabindex="0">${t(
      "components.forgotPasswordLink.label"
    )}</span></span></span></span></p>`,
    stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
    linkList: [],
  },
  props: {
    type: "WRichTextProperties",
    brightness: 1,
    packed: true,
    verticalText: false,
  },
  // @ts-ignore
  connections: {
    type: "ConnectionList",
    items: [
      {
        type: "WixCodeConnectionItem",
        role: "forgotPasswordLink",
      },
      {
        type: "ConnectionItem",
        role: "forgotPasswordLink",
        isPrimary: true,
        controllerId: "dataItem-kdy6319y",
      },
    ],
  },
  style: "txtNew",
  activeModes: {},
  mobileStructure: {
    layout: {
      width: 260,
      height: 20,
      x: 20,
      y: 296,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    props: {
      type: "WRichTextProperties",
      brightness: 1,
      packed: true,
      verticalText: false,
    },
  },
});

const generalErrMsg = (t: TFunction): ComponentDefinition => {
  return {
    type: "Component",
    id: "comp-kdy631bc2",
    skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
    layout: {
      width: 300,
      height: 18,
      x: 83.5,
      y: 349,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: "wysiwyg.viewer.components.WRichText",
    parent: "comp-kdy631a0",
    data: {
      type: "StyledText",
      text: `<p class="font_8" style="font-size: 14px; line-height: 1.3em; text-align: center;"><span style="color:#FF3D3D"><span style="letter-spacing:0.03em">${t(
        "components.general.errorPlaceholder"
      )}</span></span></p>`,
      stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
      linkList: [],
      id: "dataItem-kdy631bd",
    },
    props: {
      type: "WRichTextProperties",
      isHidden: false,
      brightness: 1,
      packed: true,
      minWidth: null,
      verticalText: false,
      id: "propItem-kdy631bd1",
    },
    // @ts-ignore
    connections: {
      type: "ConnectionList",
      items: [
        {
          type: "WixCodeConnectionItem",
          role: "generalErrMsg",
        },
        {
          type: "ConnectionItem",
          role: "generalErrMsg",
          isPrimary: true,
          controllerId: "dataItem-kdy6319y",
        },
      ],
      id: "connection-kdy631be",
    },
    style: "txtNew",
    mobileStructure: {
      layout: {
        width: 260,
        height: 36,
        x: 20,
        y: 318,
        scale: 0.9333333333333333,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: "WRichTextProperties",
        isHidden: false,
        brightness: 1,
        packed: true,
        verticalText: false,
        id: "mobile_propItem-kdy631bd1",
      },
    },
  };
};

const submit = (t: TFunction): ComponentDefinition => {
  const label = t("components.submit.label");
  return {
    type: "Component",
    id: "comp-kdy631be1",
    skin: "wysiwyg.viewer.skins.button.BasicButton",
    layout: {
      width: 300,
      height: 46,
      x: 83.5,
      y: 405,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: "wysiwyg.viewer.components.SiteButton",
    parent: "comp-kdy631a0",
    data: {
      type: "LinkableButton",
      label,
      id: "dataItem-kdy631bf",
      link: { type: "FormSubmitButtonLink" },
    },
    props: {
      type: "ButtonProperties",
      align: "center",
      margin: 0,
      id: "propItem-kdy631bh",
    },
    behaviors: {
      type: "ObsoleteBehaviorsList",
      items: "[]",
      // @ts-ignore
      id: "behavior-kdy631bh1",
    },
    connections: {
      type: "ConnectionList",
      items: [
        {
          type: "WixCodeConnectionItem",
          role: "submit",
        },
        {
          type: "ConnectionItem",
          role: "submit",
          isPrimary: true,
          controllerId: "dataItem-kdy6319y",
        },
      ],
      id: "connection-kdy631bi1",
    },
    style: {
      id: "style-kdy631bi",
      type: "ComponentStyle",
      style: {
        properties: {
          "alpha-bg": "1",
          "alpha-bgh": "1",
          "alpha-brd": "1",
          "alpha-brdh": "0",
          "alpha-txt": "1",
          "alpha-txth": "1",
          bg: "color_15",
          bgh: "#000000",
          "boxShadowToggleOn-shd": "false",
          brd: "color_15",
          brdh: "color_11",
          brw: "0",
          fnt: "font_8",
          rd: "0px",
          shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
          txt: "color_11",
          txth: "color_11",
        },
        propertiesSource: {
          "alpha-bg": "value",
          "alpha-bgh": "value",
          "alpha-brd": "value",
          "alpha-brdh": "value",
          "alpha-txt": "value",
          "alpha-txth": "value",
          bg: "theme",
          bgh: "value",
          "boxShadowToggleOn-shd": "value",
          brd: "theme",
          brdh: "theme",
          brw: "value",
          fnt: "theme",
          rd: "value",
          shd: "value",
          txt: "theme",
          txth: "theme",
        },
        groups: {},
      },
      componentClassName: "wysiwyg.viewer.components.SiteButton",
      pageId: "",
      compId: "",
      styleType: "custom",
      skin: "wysiwyg.viewer.skins.button.BasicButton",
    },
    mobileStructure: {
      layout: {
        width: 260,
        height: 42,
        x: 20,
        y: 366,
        scale: 1.0666666666666667,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: "ButtonProperties",
        align: "center",
        margin: 0,
        id: "propItem-kdy631bh",
      },
    },
  };
};

const socialLabel = (t: TFunction): ComponentDefinition => {
  return {
    type: "Component",
    skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
    layout: {
      width: 300,
      height: 19,
      x: 83.5,
      y: 467,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: "wysiwyg.viewer.components.WRichText",
    parent: "comp-kdy631a0",
    data: {
      type: "StyledText",
      text: `<p class="font_8" style="line-height:1.3em; text-align:center"><span class="color_15" style="letter-spacing:0.03em">${t(
        "components.socialLabel.label"
      )}</span></p>`,
      stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
      linkList: [],
    },
    props: {
      type: "WRichTextProperties",
      brightness: 1,
      packed: true,
      verticalText: false,
    },
    // @ts-ignore
    connections: {
      type: "ConnectionList",
      items: [
        {
          type: "WixCodeConnectionItem",
          role: "socialDividerLabel",
        },
        {
          type: "ConnectionItem",
          role: "socialDividerLabel",
          isPrimary: true,
          controllerId: "dataItem-kdy6319y",
        },
      ],
    },
    style: "txtNew",
    mobileStructure: {
      layout: {
        width: 280,
        height: 10,
        x: 10,
        y: 417,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      props: {
        type: "WRichTextProperties",
        brightness: 1,
        packed: true,
        verticalText: false,
      },
    },
    activeModes: {},
  };
};

const socialDivider = (t: TFunction): ComponentDefinition => {
  return {
    type: "Container",
    skin: "wysiwyg.viewer.components.GroupSkin",
    layout: {
      x: 148,
      y: 433,
      fixedPosition: false,
      width: 303,
      height: 20,
      scale: 1,
      rotationInDegrees: 0,
    },
    componentType: "wysiwyg.viewer.components.Group",
    components: [
      {
        type: "Component",
        layout: {
          x: 0,
          y: 10,
          fixedPosition: false,
          width: 93,
          height: 5,
          scale: 1,
          rotationInDegrees: 0,
        },
        componentType: "wysiwyg.viewer.components.FiveGridLine",
        parent: "comp-kholu0ul",
        props: {
          type: "FiveGridLineProperties",
          fullScreenModeOn: false,
        },
        // @ts-ignore
        connections: {
          type: "ConnectionList",
          items: [
            {
              type: "WixCodeConnectionItem",
              role: "line1",
            },
          ],
        },
        skin: "wysiwyg.viewer.skins.line.SolidLine",
        // @ts-ignore
        style: {
          type: "ComponentStyle",
          styleType: "custom",
          style: {
            groups: {},
            properties: {
              "alpha-brd": "1",
              brd: "color_15",
              lnw: "1px",
            },
            propertiesSource: {
              "alpha-brd": "value",
              brd: "theme",
              lnw: "value",
            },
          },
        },
        mobileStructure: {
          layout: {
            width: 50,
            height: 5,
            x: 182,
            y: 9,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          props: {
            type: "FiveGridLineProperties",
            fullScreenModeOn: false,
            id: "propItem-kjb869bx",
          },
        },
      },
      {
        type: "Component",
        layout: {
          x: 209,
          y: 10,
          fixedPosition: false,
          width: 93,
          height: 5,
          scale: 1,
          rotationInDegrees: 0,
        },
        componentType: "wysiwyg.viewer.components.FiveGridLine",
        parent: "comp-kholu0ul",
        props: {
          type: "FiveGridLineProperties",
          fullScreenModeOn: false,
        },
        // @ts-ignore
        connections: {
          type: "ConnectionList",
          items: [
            {
              type: "WixCodeConnectionItem",
              role: "line2",
            },
          ],
        },
        skin: "wysiwyg.viewer.skins.line.SolidLine",
        // @ts-ignore
        style: {
          type: "ComponentStyle",
          styleType: "custom",
          style: {
            groups: {},
            properties: {
              "alpha-brd": "1",
              brd: "color_15",
              lnw: "1px",
            },
            propertiesSource: {
              "alpha-brd": "value",
              brd: "theme",
              lnw: "value",
            },
          },
        },
        mobileStructure: {
          layout: {
            width: 50,
            height: 5,
            x: 0,
            y: 9,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          props: {
            type: "FiveGridLineProperties",
            fullScreenModeOn: false,
          },
        },
      },
      {
        type: "Component",
        skin: "wysiwyg.viewer.skins.WRichTextNewSkin",
        layout: {
          x: 1,
          y: 0,
          fixedPosition: false,
          width: 302,
          height: 20,
          scale: 1,
          rotationInDegrees: 0,
        },
        componentType: "wysiwyg.viewer.components.WRichText",
        parent: "comp-kholu0ul",
        data: {
          type: "StyledText",
          text: `<p class="font_8" style="line-height:1.3em; text-align:center"><span style="letter-spacing:0.03em" class="color_15">${t(
            "components.socialLabel.label"
          )}</span></p>`,
          stylesMapId: "CK_EDITOR_PARAGRAPH_STYLES",
          linkList: [],
        },
        props: {
          type: "WRichTextProperties",
          brightness: 1,
          packed: true,
          verticalText: false,
          minWidth: null,
        },
        // @ts-ignore
        connections: {
          type: "ConnectionList",
          items: [
            {
              type: "WixCodeConnectionItem",
              role: "socialDividerLabel",
            },
            {
              type: "ConnectionItem",
              role: "socialDividerLabel",
              isPrimary: true,
              controllerId: "dataItem-kdy6319y",
            },
          ],
        },
        style: "txtNew",
        mobileStructure: {
          layout: {
            width: 127,
            height: 20,
            x: 48,
            y: 0,
            scale: 0.75,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          props: {
            type: "WRichTextProperties",
            brightness: 1,
            packed: true,
            verticalText: false,
          },
        },
      },
    ],
    parent: "comp-kholpa88",
    connections: {
      type: "ConnectionList",
      items: [
        {
          type: "WixCodeConnectionItem",
          role: "group1",
        },
      ],
    },
    activeModes: {},
    mobileHints: {
      type: "MobileHints",
      hidden: false,
    },
    mobileStructure: {
      layout: {
        x: 32,
        y: 405,
        width: 232,
        height: 20,
        rotationInDegrees: 0,
      },
      props: {
        isSocialLoginGoogleEnabled: true,
        isSmallIcons: true,
        isSocialLoginFacebookEnabled: true,
        mode: "login",
        type: "SocialAuthProperties",
      },
    },
  };
};

const socialComponent: ComponentDefinition = {
  type: "Component",
  skin: "wixui.skins.SocialAuth",
  layout: {
    width: 300,
    height: 63,
    x: 83.5,
    y: 498,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: "wixui.SocialAuth",
  parent: "fs0js",
  data: {
    type: "SocialAuth",
  },
  props: {
    isSocialLoginGoogleEnabled: true,
    isSmallIcons: true,
    isSocialLoginFacebookEnabled: true,
    formType: "custom",
    mode: "login",
    type: "SocialAuthProperties",
  },
  connections: {
    type: "ConnectionList",
    items: [
      {
        type: "WixCodeConnectionItem",
        role: "socialComponent",
      },
      {
        type: "ConnectionItem",
        role: "socialComponent",
        isPrimary: true,
        controllerId: "dataItem-kdy6319y",
      },
    ],
  },
  style: {
    type: "ComponentStyle",
    // @ts-ignore
    style: {
      properties: {
        "$st-css":
          ":import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: SocialAuth;} .root { -st-extends: SocialAuth; background: red }",
      },
    },
    componentClassName: "wixui.SocialAuth",
    styleType: "custom",
    skin: "wixui.skins.Skinless",
  },
  activeModes: {},
  mobileStructure: {
    layout: {
      width: 300,
      height: 63,
      x: 0,
      y: 441,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    props: {
      isSocialLoginGoogleEnabled: true,
      isSmallIcons: true,
      isSocialLoginFacebookEnabled: true,
      mode: "login",
      type: "SocialAuthProperties",
    },
  },
};

export const requiredComponentsDefinitionMap = (t: TFunction) => {
  return {
    // @ts-ignore layoutResponsive
    signUpLink: signUpLink(t),
    // @ts-ignore layoutResponsive
    email: email(t),
    // @ts-ignore layoutResponsive
    // emailErrMsg,
    // @ts-ignore layoutResponsive
    password: password(t),
    // @ts-ignore layoutResponsive
    // passwordErrMsg,
    // @ts-ignore layoutResponsive
    forgotPasswordLink: forgotPasswordLink(t),
    // @ts-ignore layoutResponsive
    generalErrMsg,
    // @ts-ignore layoutResponsive
    submit: submit(t),
    // @ts-ignore layoutResponsive
    // socialIframe,
    socialComponent,
  };
};

export const getCustomSignInDefinition = (
  appDefinitionId: string,
  controllerType: string,
  t: TFunction
) => {
  return {
    type: "Container",
    id: "comp-kdy630go",
    layout: {
      x: 20,
      y: 20,
      fixedPosition: false,
      width: 467,
      height: 609,
      scale: 1,
      rotationInDegrees: 0,
    },
    componentType: "platform.components.AppWidget",
    components: [
      {
        type: "Container",
        id: "comp-kdy631a0",
        skin: "wysiwyg.viewer.skins.FormContainerSkin",
        layout: {
          x: 0,
          y: 0,
          fixedPosition: false,
          width: 467,
          height: 609,
          scale: 1,
          rotationInDegrees: 0,
        },
        componentType: "wysiwyg.viewer.components.FormContainer",
        components: [
          popupCloseIconButton,
          title(t),
          signUpLink(t),
          email(t),
          // emailErrMsg(t),
          password(t),
          // passwordErrMsg(t),
          forgotPasswordLink(t),
          generalErrMsg(t),
          submit(t),
          // horizontalLine,
          socialLabel(t),
          // socialDivider(t),
          // socialIframe,
          socialComponent,
        ],
        parent: "comp-kdy630go",
        connections: {
          type: "ConnectionList",
          items: [
            {
              type: "WixCodeConnectionItem",
              role: "formContainer",
            },
            {
              type: "ConnectionItem",
              role: "formContainer",
              isPrimary: true,
              controllerId: "dataItem-kdy6319y",
            },
          ],
          id: "connection-kdy631ai1",
        },
        style: {
          id: "style-ki8ugnlb",
          type: "ComponentStyle",
          style: {
            properties: {
              loader: "0",
            },
            propertiesSource: {
              loader: "value",
            },
            groups: {},
          },
          componentClassName: "wysiwyg.viewer.components.FormContainer",
          pageId: "",
          compId: "comp-jrhw8ish",
          styleType: "custom",
          skin: "wysiwyg.viewer.skins.FormContainerSkin",
        },
        mobileStructure: {
          layout: {
            width: 300,
            height: 646,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
        },
      },
    ],
    parent: "c1dmp",
    data: {
      type: "AppController",
      applicationId: appDefinitionId,
      name: "Widget1",
      controllerType,
      id: "dataItem-kdy6319y",
    },
    connections: {
      type: "ConnectionList",
      items: [
        {
          type: "WixCodeConnectionItem",
          role: "customLogin",
        },
      ],
      id: "connection-kdy6319z1",
    },
    style: "appWidget1",
    activeModes: {},
    mobileStructure: {
      layout: {
        width: 300,
        height: 646,
        x: -10,
        y: 15,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
    },
  };
};
